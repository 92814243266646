module.exports = {
  // update event
  tickers: "tickers",
  update: "update",
  trades: "trades",
  publicTrades: "publicTrades",
  account: "account",
  order: "order",
  trade: "trade",
  candleOnUpdate: "candleOnUpdate",
};
