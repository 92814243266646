import React, { useContext, useEffect, useState } from "react";
import HomeFooter from "../components/HomeFooter";
import HomeHeader from "../components/HomeHeader";
import HomeSidebar from "../components/HomeSidebar";
import StoreContext from "../store/store-context";
import { MODE_CLASS } from "../constant/Mode";
import { useTranslation } from "react-i18next";

const About = () => {
  const [active, setActive] = useState(false);
  const sidebarHandler = () => setActive((prev) => !prev);
  const storeCtx = useContext(StoreContext);

  const { t } = useTranslation();

  const [mode, setMode] = useState(storeCtx.mode);

  useEffect(() => {
    if (mode !== storeCtx.mode) setMode(storeCtx.mode);
  }, [mode, storeCtx.mode]);

  return (
    <div className={`home ${MODE_CLASS[mode]}`}>
      <HomeHeader sidebarHandler={sidebarHandler} />
      <HomeSidebar active={active} sidebarHandler={sidebarHandler} />
      <div className="home__about about">
        {" "}
        <div className="about__container">
          <h1 className="about__heading">{t("about_title")}</h1>{" "}
        </div>
        <div className="about__container">
          <h1 className="">{t("about_subtitle")}</h1>{" "}
        </div>
        {/** when the screen width is below `$screen-md`, set the img first and the div of text one by one vertically */}
        <div className="about__section-container">
          <img
            className="about__container__img-left about__container__img-left__tower"
            src={`${storeCtx.mode}_mode/sec1_icon01.png`}
            alt=""
          />
          <div className="about__container__text">
            <h1 className="">{t("about_title_1")}</h1>{" "}
            <p className="">{t("about_content_1")}</p>{" "}
          </div>
        </div>
        {/** when the screen width is below `$screen-md`, set the img first and the div of text one by one vertically */}
        <div className="about__section-container">
          <div className="about__container__text">
            <h1 className="">{t("about_title_2")}</h1>{" "}
            <p className="">{t("about_content_2")}</p>{" "}
          </div>{" "}
          <img
            className="about__container__img-right"
            src={`${storeCtx.mode}_mode/sec1_icon02.png`}
            alt=""
          />
        </div>
        {/** when the screen width is below `$screen-md`, set the img first and the div of text one by one vertically */}
        <div className="about__section-container">
          <img
            className="about__container__img-left"
            src={`${storeCtx.mode}_mode/sec1_icon03.png`}
            alt=""
          />
          <div className="about__container__text">
            <h1 className="">{t("about_title_3")}</h1>{" "}
            <p className="">{t("about_content_3")}</p>{" "}
          </div>{" "}
        </div>
        {/** when the screen width is below `$screen-md`, set the img first and the div of text one by one vertically */}
        <div className="about__section-container">
          <div className="about__container__text">
            <h1 className="">{t("about_title_4")}</h1>{" "}
            <p className="">{t("about_content_4")}</p>{" "}
          </div>{" "}
          <img
            className="about__container__img-right"
            src={`${storeCtx.mode}_mode/sec1_icon04.png`}
            alt=""
          />
        </div>
        {/** when the screen width is below `$screen-md`, set the img first and the div of text one by one vertically */}
        <div className="about__section-container">
          <img
            className="about__container__img-left"
            src={`${storeCtx.mode}_mode/sec1_icon05.png`}
            alt=""
          />
          <div className="about__container__text">
            <h1 className="">{t("about_title_5")}</h1>{" "}
            <p className="">{t("about_content_5")}</p>{" "}
          </div>{" "}
        </div>
        <div className="about__container__hero">
          <div className="about__container__text__title">
            <h1 className="">{t("about_hero_1")}</h1>{" "}
            <p className="">{t("about_hero_2")}</p>{" "}
          </div>
        </div>
        <div className="about__section-big-container">
          <img
            className="about__container__img-left"
            src={`${storeCtx.mode}_mode/jered-kenna.png`}
            alt=""
          />
          <div className="about__container__text">
            <p className="">{t("about_cta_1")}</p>{" "}
            <p className="">{t("about_cta_2")}</p>{" "}
          </div>
        </div>
        <div className="about__container">
          <h3 className="">{t("about_cta_3")}</h3>{" "}
        </div>
        <div className="about__container">
          <div className="about__item">
            <a className="" href="/signup">
              {t("about_cta_button")}
            </a>
          </div>
        </div>{" "}
      </div>

      <HomeFooter />
    </div>
  );
};

export default About;
