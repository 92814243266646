import { useTranslation } from "react-i18next";
import React, { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import StoreContext from "../store/store-context";
import Layout from "../components/Layout";
import DropDown from "../components/DropDown";
import TwoFactorAuth from "../components/TwoFactorAuth";

const FundSources = () => {
  const storeCtx = useContext(StoreContext);
  const { t } = useTranslation();
  const history = useHistory();
  const defaultAuthWay = "two_factor_auth_app";

  // TODO: fetch from backend
  const supportCurrencies = ["BTC", "ETH", "USDT"];
  const [authWay, setAuthWay] = useState("defaultAuthWay");
  const [showAlert, setShowAlert] = useState(false);
  const [alertText, seAlertText] = useState(t("two_factor_alert"));

  const [formData, setFormData] = useState({
    currency: supportCurrencies[0],
    description: "",
    address: "",
    otp: "",
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setShowAlert(false);
    setFormData((formData) => ({
      ...formData,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    console.log("handleSubmit");
    const _formData = { ...formData };
    _formData.type = authWay.replace("two_factor_auth_", "");
    console.log(_formData);
    e.preventDefault();
    try {
      if (!_formData.otp) {
        setShowAlert(true);
        seAlertText("two_factor_alert");
        return;
      }
      await storeCtx.bindWithdrawAddress(_formData);
      history.push("/accounts");
    } catch (error) {
      console.log(`handleSubmit: error`, error);
      setShowAlert(true);
      seAlertText("bind_withdraw_address_fail");
    }
  };

  return (
    <Layout hasBottomNavigator={false} hasFooter={true}>
      <div className="fund_sources">
        {showAlert && <div className="fund_sources__alert">{t(alertText)}</div>}
        <div className="fund_sources__header">{t("fund_sources_header")}</div>
        <div className="fund_sources__container">
          <form className="fund_sources__form" onSubmit={handleSubmit}>
            <DropDown
              options={supportCurrencies}
              selected={formData.currency}
              onSelect={(currency) => setFormData({ ...formData, currency })}
              placeholder=""
            >
              {(currency, index) => (
                <option
                  className={`fund_sources__dropdown--option ${
                    formData.currency === currency ? "selected" : ""
                  }`}
                  value={`${currency}`}
                  key={`fund_sources_${currency}_${index}`}
                >
                  {currency}
                </option>
              )}
            </DropDown>
            <label className="fund_sources__label">
              <input
                className="fund_sources__input"
                name="description"
                value={formData.description}
                onChange={handleInputChange}
                placeholder={t("source_description")}
              />
            </label>
            <label className="fund_sources__label">
              <input
                className="fund_sources__input"
                name="address"
                value={formData.address}
                onChange={handleInputChange}
                placeholder={t("source_address")}
              />
            </label>
            <div className="fund_sources__warning">
              {t("fund_sources_warning")}
            </div>
            <TwoFactorAuth
              defaultAuthWay={defaultAuthWay}
              setAuthWay={(authWay) => setAuthWay(authWay)}
              handleChange={handleInputChange}
              otp={formData.otp}
            />
            <button
              className="fund_sources__button fund_sources__button--primary"
              type="submit"
              onSubmit={handleSubmit}
            >
              {t("submit")}
            </button>
          </form>
          <a
            className="fund_sources__button fund_sources__box"
            href="/accounts"
          >
            {t("return")}
          </a>
        </div>
      </div>
    </Layout>
  );
};

export default FundSources;
