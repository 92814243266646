import React, { useContext, useEffect, useState } from "react";
import HomeFooter from "../components/HomeFooter";
import HomeHeader from "../components/HomeHeader";
import HomeSidebar from "../components/HomeSidebar";
import StoreContext from "../store/store-context";
import { MODE_CLASS } from "../constant/Mode";
import { useTranslation } from "react-i18next";

const Referral = () => {
  const [active, setActive] = useState(false);
  const sidebarHandler = () => setActive((prev) => !prev);
  const storeCtx = useContext(StoreContext);

  const { t } = useTranslation();

  const [mode, setMode] = useState(storeCtx.mode);
  const rows = Array.from({ length: 12 }, (_, i) => i + 1);

  useEffect(() => {
    if (mode !== storeCtx.mode) setMode(storeCtx.mode);
  }, [mode, storeCtx.mode]);

  return (
    <div className={`home ${MODE_CLASS[mode]}`}>
      <HomeHeader sidebarHandler={sidebarHandler} />
      <HomeSidebar active={active} sidebarHandler={sidebarHandler} />
      <div className="home__referral referral">
        {" "}
        <div className="referral__container">
          <h1 className="referral__heading">{t("referral_title")}</h1>{" "}
        </div>
        <div className="referral__container referral__items">
          <div className="referral__item">
            <a className="" href="/signin">
              {t("login")}
            </a>
          </div>
          <div className="referral__item">
            <a className="" href="/signup">
              {t("register")}
            </a>
          </div>
        </div>
        <div className="referral__boxes">
          <div className="referral__box">
            <img
              className="referral__image-box"
              src={`${storeCtx.mode}_mode/sign_up.png`}
              alt=""
            />
            <h2 className="referral__header referral__box_item">
              {t("referral_step_1_title")}
            </h2>
            <div className="referral__subdivider" />
            <div className="">
              <span>{t("referral_step_1_content_1")}</span>
              <a className="referral__link" href="/signin">
                {t("referral_step_1_content_2")}
              </a>
              <span>{t("referral_step_1_content_3")}</span>
            </div>
          </div>

          <div className="referral__box">
            <img
              className="referral__image-box"
              src={`${storeCtx.mode}_mode/refer.png`}
              alt=""
            />
            <h2 className="referral__header">{t("referral_step_2_title")}</h2>
            <div className="referral__subdivider" />
            <div className="referral__text">
              {t("referral_step_2_content_1")}"{t("referral_step_2_content_2")}"
              {t("referral_step_2_content_3")}
            </div>
          </div>

          <div className="referral__box">
            <img
              className="referral__image-box"
              src={`${storeCtx.mode}_mode/earn.png`}
              alt=""
            />
            <h2 className="referral__header">{t("referral_step_3_title")}</h2>
            <div className="referral__subdivider" />
            <div className="referral__text">
              {t("referral_step_3_content")}
            </div>{" "}
          </div>

          <div className="referral__box">
            <img
              className="referral__image-box"
              src={`${storeCtx.mode}_mode/track.png`}
              alt=""
            />
            <h2 className="referral__header">{t("referral_step_4_title")}</h2>
            <div className="referral__subdivider" />
            <div className="referral__text">
              {t("referral_step_4_content")}
            </div>{" "}
          </div>
        </div>
        <div className="referral__overlay">
          <div className="referral__divider"></div>
          <div
            className={`referral__overlay-text referral__overlay-text__${MODE_CLASS[mode]}`}
          >
            {t("referral_divider")}
          </div>
        </div>
        <div className="referral__content">
          <div className="referral__container">
            <table className="referral__table">
              <thead>
                <tr>
                  <th>{t("referral_table_col_1_header_1")}</th>
                  <th>
                    {t("referral_table_col_2_header_1")}
                    <br />
                    {t("referral_table_col_2_header_2")}
                  </th>
                </tr>
              </thead>
              <tbody>
                {rows.map((row) => (
                  <tr key={row}>
                    <td>{t(`referral_table_col_1_row_${row}`)}</td>
                    <td>{t(`referral_table_col_2_row_${row}`)}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <div className="referral__terms">
            <ul className="">
              {[...Array(4)].map((_, index) => (
                <li key={index}>{t(`referral_term_${index + 1}`)}</li>
              ))}
            </ul>

            <div className="referral__important-notice">
              <p className="referral__important-notice__title">
                {t("referral_important_notice_title")}
              </p>

              <p>{t("referral_important_notice_content_1")}</p>
              <p>{t("referral_important_notice_content_2")}</p>
            </div>
          </div>{" "}
        </div>
      </div>

      <HomeFooter />
    </div>
  );
};

export default Referral;
