const Codes = {
  SUCCESS: '00000000',

  // Config error
  CONFIG_MISSING_APIKEY: '03000000',

  // API error
  INVALID_INPUT_EXCHANGE: '04000000',
  API_NOT_SUPPORTED: '04000001',
  THIRD_PARTY_API_ERROR: '04000002',
  MEMBER_ID_NOT_FOUND: '04000003',
  ORDER_HAS_BEEN_CLOSED: '04000004',
  CANCEL_ORDER_FAIL: '04000005',
  DB_OPERATION_ERROR: '04000006',
  POST_ORDER_FAIL: '04000007',
  API_UNKNOWN_ERROR: '04999999',
  USER_IS_LOGOUT: '04000009',

  // WebSocket error
  INVALID_INPUT_WEBSOCKET_OPERATION: '05000000',
  INVALID_INPUT_WEBSOCKET_DATA: '05000001',

  // Uncaught Exception or Unknown Error 09000000
  UNKNOWN_ERROR: '09000000',
};

module.exports = Codes;
