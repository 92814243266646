import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import StoreContext from "../store/store-context";
import packageJson from "../../package.json";

const HomeFooter = () => {
  const storeCtx = useContext(StoreContext);
  const year = new Date().getFullYear();
  const { t } = useTranslation();
  return (
    <div className="home-footer">
      <div className="home-footer__brand">
        <img className="home-footer__brand--icon" src={`/${storeCtx.mode}_mode/footer_logo.svg`} alt="" />
        <div className="home-footer__brand--decorator"></div>
        <div className="home-footer__brand--links">
          <a
            className="home-footer__social-media"
            href="https://t.me/tidebitofficial"
            target="_blank"
            rel="noreferrer"
          >
            <img className="home-footer__icon home-footer__icon--telegram" src={`/${storeCtx.mode}_mode/telegram.svg`} alt="" />
          </a>
          {/* <a
            className="home-footer__social-media"
            href="https://www.facebook.com/tidebit"
            target="_blank"
            rel="noreferrer"
          >
            <img className="home-footer__icon home-footer__icon--facebook" src={`${storeCtx.mode}_mode/facebook.svg`} alt="" />
          </a>
          <a
            className="home-footer__social-media"
            href="https://www.linkedin.com/company/tidebit"
            target="_blank"
            rel="noreferrer"
          >
            <img className="home-footer__icon home-footer__icon--linkedin" src={`${storeCtx.mode}_mode/linkedin.svg`} alt="" />
          </a> */}
        </div>
      </div>
      <div className="home-footer__container">
        <div className="home-footer__item">
          <div className="home-footer__header">{t("services")}</div>
          <a
            className="home-footer__link"
            href={`/markets/${storeCtx.defaultMarket}`}
          >
            {t("trading")}
          </a>
          {/* <a className="home-footer__link" href="/transfer">
            {t("transfer")}
          </a> */}
          <a
            className="home-footer__link"
            href="https://tidebit.zendesk.com/hc/zh-tw/articles/360006278834--%E5%85%B6%E4%BB%96-%E6%94%B6%E8%B2%BB%E8%A1%A8FEE-SCHEDULE"
          >
            {t("fee_schedule")}
          </a>
          <a
            className="home-footer__link"
            href="https://tidebit.zendesk.com/hc/zh-tw/sections/115002703848"
          >
            {t("faq")}
          </a>
        </div>
        <div className="home-footer__item">
          <div className="home-footer__header">{t("about_us")}</div>
          <a className="home-footer__link" href="/about">
            {t("about_tidebit")}
          </a>
          {/* <a
            className="home-footer__link"
            href="https://angel.co/tidebit/jobs"
          >
            {t("careers")}
          </a> */}
        </div>
        <div className="home-footer__item">
          <div className="home-footer__header">{t("legal_terms")}</div>
          <a className="home-footer__link" href="/tos">
            {t("terms_of_services")}
          </a>
          <a className="home-footer__link" href="/privacy">
            {t("privacy")}
          </a>
          {/* <a className="home-footer__link" href={`/statements/isuncrowd_limited_master_trading_agreement_v1.2.pdf`} target="_blank" rel="noreferrer">
            {t("master_agreement")}
          </a> */}
        </div>
        <div className="home-footer__item">
          <div className="home-footer__header">{t("customer_service")}</div>
          <div className="home-footer__text">
            <span>{`${t("general_enquiries")}: `}</span>
            <span>hello@tidebit.com</span>
          </div>
          <div className="home-footer__text">
            <span>{`${t("corporate_enquiries")}: `}</span>
            <span>corporate@tidebit.com</span>
          </div>
          <br />
          {/* <div className="home-footer__text">{t("whatsapp_hotline")}</div> */}
          <a
            className="home-footer__link"
            href="https://api.whatsapp.com/send?phone=85267356499"
          >
          </a>
        </div>
      </div>
      <div className="home-footer__copyright">
        {`©${year}, TideBit All Rights Reserved. v${packageJson.version}`}
      </div>
    </div>
  );
};

export default HomeFooter;
