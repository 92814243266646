import { useTranslation } from "react-i18next";
import React, { useContext, useEffect, useState } from "react";
import HomeFooter from "../components/HomeFooter";
import HomeHeader from "../components/HomeHeader";
import HomeSidebar from "../components/HomeSidebar";
import StoreContext from "../store/store-context";
import { MODE_CLASS } from "../constant/Mode";

const TermOfService = () => {
  const [active, setActive] = useState(false);
  const sidebarHandler = () => setActive((prev) => !prev);
  const storeCtx = useContext(StoreContext)
  const { t } = useTranslation();

  const [mode, setMode] = useState(storeCtx.mode);

  useEffect(() => {
    if (mode !== storeCtx.mode)
      setMode(storeCtx.mode);
  }, [mode, storeCtx.mode]);
  return (
    <div className={`home ${MODE_CLASS[mode]}`}>
      <HomeHeader sidebarHandler={sidebarHandler} />
      <HomeSidebar active={active} sidebarHandler={sidebarHandler} />
      <div className="home__term term">
      <div className="term__box">
        <h1>{t("term_title")}</h1>
        <p>{t("term_updated_at")}</p>
        <ol>
          <li>{t("term_1")}</li>
          <li>{t("term_2")}</li>
          <li>{t("term_3")}</li>
          <li>{t("term_4")}</li>
          <li>{t("term_5")}</li>
          <li>{t("term_6")}</li>
          <li>{t("term_7")}</li>
          <li>{t("term_8")}</li>
          <li>{t("term_9")}</li>
          <li>{t("term_10")}</li>
          <li>{t("term_11")}</li>
          <li>{t("term_12")}</li>
          <li>{t("term_13")}</li>
          <li>{t("term_14")}</li>
          <li>{t("term_15")}</li>
          {/* <li>{t("term_16")}</li> */}
        </ol>
      </div>
      </div>
      <HomeFooter />
    </div>
  );
};

export default TermOfService;
