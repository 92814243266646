module.exports = {
    ORDER_STATE: {
      OPEN: "OPEN",
      CLOSED: "CLOSED",
    },
    STATE: {
      ALL: "all",
      WAIT: "wait",
      CANCELED: "canceled",
      DONE: "done",
    },
  };
  