import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import StoreContext from "../store/store-context";

const TwoFactorAuth = (props) => {
  const { t } = useTranslation();
  const storeCtx = useContext(StoreContext);
  const [authWay, setAuthWay] = useState(props.defaultAuthWay);
  const originSMSButtonText = t("send_code");
  const [wait, setWait] = useState(false);
  const [smsButtonText, setSMSButtonText] = useState(originSMSButtonText);
  const [partialPhoneNumber, setPartialPhoneNumber] = useState("");

  const getPartialPhoneNumber = (phoneNumber) => {
    let partialPhoneNumber = phoneNumber,
      start = 0;
    try {
      phoneNumber = String(phoneNumber);
      const phoneNumberSplit = phoneNumber.split(" ");
      if (phoneNumberSplit.length === 1) {
        start = Math.floor((phoneNumber.length - 4) / 2);
        partialPhoneNumber =
          phoneNumber.substring(0, start) +
          "****" +
          phoneNumber.substring(start + 4);
      } else {
        start = Math.floor((phoneNumberSplit[1].length - 4) / 2);
        partialPhoneNumber =
          phoneNumberSplit[0] +
          " " +
          phoneNumberSplit[1].substring(0, start) +
          "****" +
          phoneNumberSplit[1].substring(start + 4);
      }
      return partialPhoneNumber;
    } catch (error) {
      console.error("Error in partialPhoneNumber", {
        error: error,
      });
    }
  };

  const handleSendSms = async (e) => {
    console.log("handleSendSms");
    try {
      setWait(true);
      e.preventDefault();
      setSMSButtonText(t("resend", { seconds: 60 }));
      let seconds = 60;
      const myInterval = setInterval(function () {
        seconds -= 1;
        if (seconds === 0) {
          setSMSButtonText(originSMSButtonText);
          clearInterval(myInterval);
          setWait(false);
        } else {
          setSMSButtonText(t("resend", { seconds }));
        }
      }, 1000);
      await storeCtx.sendSmsCode();
    } catch (error) {
      console.log(`handleSendSms: error`, error);
    }
  };

  useEffect(() => {
    if (wait) {
      setPartialPhoneNumber(getPartialPhoneNumber("+86 13764153224")); // TODO: '+86 13764153224' is a mock phone number, switch storeCtx.memberPhone
    }
  }, [storeCtx, wait]);

  return (
    <div className="two_factor">
      <div className="two_factor__divider">
        <div className="two_factor__divider--border"></div>
        <div className="two_factor__divider--text">
          {t("fund_sources_divider")}
        </div>
      </div>
      <div className="two_factor__auth">
        <div
          className={`two_factor__auth--button ${authWay === "two_factor_auth_app" ? `active` : ""
            }`}
          id="two_factor_auth_app"
          onClick={(e) => {
            props.setAuthWay(e.target.id);
            setAuthWay(e.target.id);
          }}
        >
          {t("google_auth")}
        </div>
        <div
          className={`two_factor__auth--button ${authWay === "two_factor_auth_sms" ? `active` : ""
            }`}
          id="two_factor_auth_sms"
          onClick={(e) => {
            props.setAuthWay(e.target.id);
            setAuthWay(e.target.id);
          }}
        >
          {t("sms_auth")}
        </div>
      </div>
      <label className="fund_sources__label">
        <input
          id="two_factor_otp"
          name="two_factor[otp]"
          className="fund_sources__input"
          type="text"
          value={props.otp}
          onChange={props.handleChange}
          placeholder={t(authWay)}
        />
      </label>
      {authWay === "two_factor_auth_sms" && (
        <div className="two_factor__box">
          <button
            className={`fund_sources__button fund_sources__button--highlight ${wait ? "disabled" : ""
              }`}
            type="send-code"
            onClick={handleSendSms}
          >
            {smsButtonText}
          </button>
          <p className="two_factor__tip">
            {t("sms_send_tip", { partialPhoneNumber })}
          </p>
        </div>
      )}
    </div>
  );
};

export default TwoFactorAuth;
