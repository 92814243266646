import React from 'react';
import { useTranslation } from "react-i18next";

const NotificationPopup = ({ onConfirm, onCancel }) => {
    const { t } = useTranslation();
    return (
        <div className='warning__overlay'>
            <div className='warning__popup'>
                <p className='warning__text'>{t('hk_location_warning')}</p>
                <button className='common__button warning__button' onClick={onConfirm}>{t('confirm')}</button>
                <button className='common__button warning__button' onClick={onCancel}>{t('leave')}</button>
            </div>
        </div>
    );
};

export default NotificationPopup;
