import { useTranslation } from "react-i18next";
import React, { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import StoreContext from "../store/store-context";
import Layout from "../components/Layout";
import TwoFactorAuth from "../components/TwoFactorAuth";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";

const TransferOut = () => {
  const defaultAuthWay = "two_factor_auth_app";
  const location = useLocation();
  const fundSourceId = location.pathname.split("/")[2];
  const [isCalled, setIsCalled] = useState(false);
  const [fundSource, setFundSource] = useState(null);
  const storeCtx = useContext(StoreContext);
  const history = useHistory();
  const { t } = useTranslation();
  const [authWay, setAuthWay] = useState("two_factor_auth_app"); // "app"
  const [showAlert, setShowAlert] = useState(false);
  const [alertText, seAlertText] = useState(t("two_factor_alert"));

  const [formData, setFormData] = useState({
    amount: "",
    memo: "",
  });

  const handleInputChange = (e) => { };

  const handleSubmit = async (e) => { };

  // !!!TODO: check amount is valid function (20240422 - tzuhan)

  useEffect(() => {
    if (fundSourceId && !fundSource && !isCalled) {
      setIsCalled(true);
      storeCtx.getFundSource(fundSourceId).then((fundSource) => {
        setFundSource(fundSource);
        console.log(fundSource);
      });
    }
  }, [fundSource, fundSourceId, isCalled, storeCtx]);

  return (
    <Layout hasBottomNavigator={false} hasFooter={true}>
      <div className="fund_sources transfer_out">
        {showAlert && <div className="fund_sources__alert">{t(alertText)}</div>}
        <div className="fund_sources__header">{t("transfer_out_header")}</div>
        <div className="fund_sources__container">
          <form
            className="fund_sources__form"
            id="new_withdraw"
            action={`/fund_sources/${fundSourceId}/withdraws`}
            method="post"
          >
            <div className="transfer_out__label">
              <div>{fundSource?.extra || ""}</div>
            </div>
            <div className="transfer_out__info transfer_out__info--column">
              <div className="transfer_out__info--header">
                {t("fund_source_title_address")}:
              </div>
              <div className="transfer_out__info--content">
                {fundSource?.uid || ""}
              </div>
            </div>
            <label className="fund_sources__label">
              <input
                id="withdraw_amount"
                name="withdraw[amount]"
                type="text"
                className="fund_sources__input"
                value={formData.amount}
                onChange={handleInputChange}
                placeholder={t("amount")}
              />
            </label>
            <div className="transfer_out__info">
              <div className="transfer_out__info--header">
                {t("fund_source_title_balance")}:
              </div>
              <div className="transfer_out__info--content">
                {storeCtx.getAccount(fundSource?.code)?.balance || ""}
              </div>
            </div>
            <label className="fund_sources__label">
              <input
                id="withdraw_memo"
                name="withdraw[memo]"
                type="text"
                className="fund_sources__input"
                value={formData.memo}
                onChange={handleInputChange}
                placeholder={t("memo")}
              />
            </label>
            <a
              className="transfer_out__link"
              href="https://tidebit.zendesk.com/hc/zh-tw/articles/360006278834--%E5%85%B6%E4%BB%96-%E6%94%B6%E8%B2%BB%E8%A1%A8FEE-SCHEDULE"
              target="_blank"
              rel="noreferrer"
            >
              {t('transfer_out_fee')}
            </a>
            <TwoFactorAuth
              defaultAuthWay={defaultAuthWay}
              setAuthWay={(authWay) => setAuthWay(authWay)}
              handleChange={handleInputChange}
            // otp={formData.otp}
            />
            <button
              className="fund_sources__button fund_sources__button--primary"
              type="submit"
              name="commit"
              onSubmit={handleSubmit}
            >
              {t("submit")}
            </button>
          </form>
          <a
            className="fund_sources__button fund_sources__box"
            href="/accounts"
          >
            {t("return")}
          </a>
          <b className="transfer_out__warning">{t('transfer_out_warning')}</b>
        </div>
        <div className="transfer_out__box">
          <a
            className="transfer_out__link transfer_out__link--decorated"
            href={`/fund_sources/${fundSourceId}/edit`}
          >
            {t('edit_transfer_out_address')}
          </a>
          <a
            className="transfer_out__link"
            confirm="Are you sure you want to delete this withdrawal address?"
            rel="nofollow"
            data-method="delete"
            href={`/fund_sources/${fundSourceId}`}
          >
            {t('delete_transfer_out_address')}
          </a>
        </div>
      </div>
    </Layout>
  );
};

export default TransferOut;
