export const MODE_KEY = {
    DARK: 'dark',
    LIGHT: 'light'
}

export const MODE_CLASS = {
    dark: 'mode mode--dark',
    light: 'mode mode--light'
}

export const MODE = {
    dark: 'dark mode',
    light: 'light mode'
}

