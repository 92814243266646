import { Trans, useTranslation } from "react-i18next";
import React, { useContext, useEffect, useState } from "react";
import { useLocation } from 'react-router-dom';
import StoreContext from "../store/store-context";
import Layout from "../components/Layout";
import { ImCopy } from "react-icons/im";
import { RiQrCodeLine } from "react-icons/ri";
import { GrLink } from "react-icons/gr";
import QRCode from 'qrcode'

const Deposits = () => {
  const location = useLocation();
  const currency = location.pathname.split("/")[2]?.toUpperCase();
  const [showQRCode, setShowQRCode] = useState(false);
  const toggleQRCode = () => setShowQRCode(!showQRCode);
  const storeCtx = useContext(StoreContext)
  const { t } = useTranslation();

  const [address, setAddress] = useState('');

  const warnings = {
    'BTC': [],
    'ETH': ['erc20_deposits_warning_1', 'eth_deposits_warning_1'],
    'USDT': ['erc20_deposits_warning_1', 'usdt_deposits_warning_1', 'usdt_deposits_warning_2'],
  }

  const language = {
    'zh-HK': 'zh-hant/',
    'zh-CN': 'zh-hans/',
    'en-US': '',
  }

  useEffect(() => {
    storeCtx.getDepositAddress(currency).then((address) => {
      setAddress(address)
      QRCode.toCanvas(document.getElementById('canvas'), address, {
        width: 164,
        color: {
          dark: '#182748',
          light: '#00000000',
        }
      }, function (error) {
      })
    })
  }, [currency, storeCtx]);

  return (
    <Layout hasBottomNavigator={false} hasFooter={true}>
      <div className="deposits">
        <div className="deposits__container">
          <div className="deposits__header">
            {t("transfer_in", { currency })}
          </div>
          <div className="deposits__segment deposits__box">
            <ul className="deposits__warning">
              <li>{t("deposits_warning_1", { currency })}</li>
              <li>{t("deposits_warning_2", { currency })}</li>
              {warnings[currency]?.map((warning, index) => <li key={index}>{t(warning)}</li>)}
            </ul>
            <div className="deposits__address">
              <div className="deposits__address--title">
                {t("deposits_address_title", { currency })}
              </div>
              <div className="deposits__address--content">
                {address}
              </div>
            </div>
            <div className="deposit__button-box">
              <div className={`deposits__qrcode ${showQRCode ? `display` : ``}`}>
                <canvas id="canvas"></canvas>
              </div>
              <div className="deposits__sg-button deposits__copy" onClick={() => {
                navigator.clipboard.writeText(address);
                // alert("Copied the text: " + address);
              }}>
                <span className="deposits__sg-button--icon"><ImCopy /></span>
                <span className="deposits__sg-button--text">{t("copy_address")}</span>
              </div>
              <div className="deposits__sg-button deposits__toggle" onClick={toggleQRCode}>
                <span className="deposits__sg-button--icon"><RiQrCodeLine /></span>
                <span className="deposits__sg-button--text">{t("show_qr_code")}</span>
              </div>
              <a className="deposits__sg-button deposits__link" target="_blank" href={`https://www.okx.com/${language[storeCtx.languageKey]}explorer/multi-search#key=${address}`} rel="noreferrer">
                <span className="deposits__sg-button--icon"><GrLink /></span>
                <span className="deposits__sg-button--text">{t("view_explore")}</span>
              </a>
            </div>

          </div>
          <ul className="deposits__info deposits__box">
            <li className="deposits__info--value">
              {/* <span>{t("deposit_info", { currency, number: 6, time: `<b>60</b> mins`, interpolation: { escapeValue: false } })}</span> */}
              <Trans
                i18nKey="deposit_info"
                values={{ currency, number: 6, time: 60, unit: t('mins') }}
                components={[<strong key="1" />, <strong key="3" />,]}
              />
            </li>

          </ul>
          <a className="deposits__button deposits__button--highlight deposits__box" href="/accounts">
            {t("return")}
          </a>
        </div>
      </div>
    </Layout>
  );
};

export default Deposits;
