import { useTranslation } from "react-i18next";
import React, { useContext, useEffect, useState } from "react";
import HomeFooter from "../components/HomeFooter";
import HomeHeader from "../components/HomeHeader";
import HomeSidebar from "../components/HomeSidebar";
import StoreContext from "../store/store-context";
import { MODE_CLASS } from "../constant/Mode";

const Privacy = () => {
  const [active, setActive] = useState(false);
  const sidebarHandler = () => setActive((prev) => !prev);
  const storeCtx = useContext(StoreContext);
  const { t } = useTranslation();

  const [mode, setMode] = useState(storeCtx.mode);

  useEffect(() => {
    if (mode !== storeCtx.mode)
      setMode(storeCtx.mode);
  }, [mode, storeCtx.mode]);
  return (
    <div className={`home ${MODE_CLASS[mode]}`}>
      <HomeHeader sidebarHandler={sidebarHandler} />
      <HomeSidebar active={active} sidebarHandler={sidebarHandler} />
      <div className="home__privacy privacy">
        <div className="privacy__box">
          <h1>{t("privacy_title")}</h1>
          <p>{t("privacy_updated_at")}</p>
          <div className="privacy__container">
            <img className="privacy__image-box" src={`${storeCtx.mode}_mode/privacy.png`} alt="" />
          </div>
          <ul>
            <li>{t("privacy_1")}</li>
          </ul>
          <ul>
            <li className="privacy__header">{t("privacy_2-title-1")}</li>
            <li>{t("privacy_2-content-1")}</li>
            <li>{t("privacy_2-content-2")}</li>
            <li>{t("privacy_2-content-3")}</li>
            <li>{t("privacy_2-content-4")}</li>
            <li>{t("privacy_2-content-5")}</li>
            <li>{t("privacy_2-content-6")}</li>
            <li>{t("privacy_2-content-7")}</li>
            <li>{t("privacy_2-content-8")}</li>
          </ul>
          <ul>
            <li className="privacy__header">{t("privacy_3-title-1")}</li>
            <li>{t("privacy_3-content-1")}</li>
          </ul>
          <ul>
            <li className="privacy__header">{t("privacy_4-title-1")}</li>
            <li>{t("privacy_4-content-1")}</li>
          </ul>
        </div>
      </div>
      <HomeFooter />
    </div>
  );
};

export default Privacy;
